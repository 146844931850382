import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../assets/arrow-triangle-down.svg";
import box from "../../../assets//box-copy.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withTranslation, Trans } from "react-i18next";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  Add_Service_Option,
  Remove_Service_Option,
  Edit_Travel_PaymentMethod,
  Edit_Travel_Operate_Changed,
  New_Travel_PaymentMethod,
} from "../../../Redux/actions/actionNames";
import wallet2 from "../../../assets/IconProvider/wallet2.svg";
import SenderCashic from "../../../assets/ic-cash.svg";
import ReceiverCashic from "../../../assets/customer-ic-by-receiver.svg";
import balance from "../../../assets/mutual-wallet-balance.svg";
import Stripe_Add_Card from "./stripe";
import { Get_Stripe_Cards } from "../../../helper/api/index";
import PaymentIcon from "react-payment-icons-inline";
import { get_settings_dispatcher } from "../../../helper/UserData/userdate";
import { isEmpty } from "lodash";
import styles from './style.module.css';
import IconProvider from "../../../design-system/lib/IconProvider";
import { ARROW_DOWN_BOLD, ARROW_TOP_BOLD, BY_RECEIVER, CASH, WALLET } from "../../../assets";
import Typography from "../../../design-system/lib/Typography";
import SenderWalletic from "../../../assets/customer-ic-wallet.svg";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
      cardsLoaded: false,
      showpoperMethod: false,
      showpoperSide: false,
      paymentSide: [
        {
          i18: "SenderWallet",
          data: {
            paymentSide: "Sender",
            paymentMethod: "Wallet",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
        {
          i18: "SenderCash",
          data: {
            paymentSide: "Sender",
            paymentMethod: "Cash",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
        {
          i18: "ReceiverCash",
          data: {
            paymentSide: "Receiver",
            paymentMethod: "Cash",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
      ],
    };
    this.paymentSide = React.createRef();
    this.getCards = this.getCards.bind(this)
  }

  getImageOfPaymentMeyhod = (name) => {
    if (name == "SenderWallet") {
      return wallet2
    } else if (name == "SenderCash") {
      return CASH
    } else if (name == "ReceiverCash") {
      return BY_RECEIVER
    } else {
      return wallet2
    }
  }

  handleshowpoperPaymentSide = () => {
    this.setState({
      showpoperSide: !this.state.showpoperSide,
    });
  };

  handleSelectedItem = (item) => {
    this.props.dispatch(New_Travel_PaymentMethod(item.data));
    this.setState({
      showpoperSide: false,
      showpoperMethod: false,
    });
  };

  handleSelectedItemStripe = (item) => {
    this.props.dispatch(New_Travel_PaymentMethod({
      paymentSide: "Sender",
      paymentMethod: "Card",
      paymentProvider: "stripe",
      cardInfo: item,
      paymentMethodId: item.id,

    }));
    this.setState({
      showpoperSide: false,
      showpoperMethod: false,
    });
  };


  getCards(autoSelect) {
    Get_Stripe_Cards((cards) => {
      if (typeof autoSelect == "boolean" && cards.length > 0) {

        this.props.dispatch(New_Travel_PaymentMethod({
          paymentSide: "Sender",
          paymentMethod: "Card",
          paymentProvider: "stripe",
          cardInfo: cards[0],
          paymentMethodId: cards[0].id,

        }));

      }
      this.setState({
        cards
      })

    }, this.props.customer.id)
  }



  WalletCheck = (item, nextProps) => {
    let prop = nextProps ? nextProps : this.props;
    if ((item ? item.paymentSide + item.paymentMethod : prop.paymentSide + prop.paymentMethod) == "SenderWallet") {
      if (prop.price.price && prop.price.done) {
        return this.WalletBalanceBussinessCheck(prop)
      } else {
        return "grey";
      }
    } else {
      return "grey";
    }
  };

  WalletBalanceBussinessCheck = (props) => {
    try {
      if (Number(
        props.price.price.price
      ) > Number(props.balance)) {
        if (props.price.price && (props.customer.balance - props.price.price.price) >= props.customer.minimumBalance) {
          return "green";
        } else {
          return "red";
        }
      } else {
        return "green";
      }
    } catch (error) {
      return "red";
    }

  };


  setCards = (cards) => {
    this.setState({
      cards
    })
  }

  componentDidMount() {
    if ((get_settings_dispatcher().stripePublicApiKey && get_settings_dispatcher().stripePublicApiKey.length > 0) && this.props.customer.id && this.props.customer.id.length > 2 && this.state.cards.lenght == 0) {
      Get_Stripe_Cards(this.setCards, this.props.customer.id)
    }

  }


  componentWillReceiveProps(nextProps) {

    if ("SenderWallet" == nextProps.paymentSide + nextProps.paymentMethod && ["grey", "red"].indexOf(this.WalletCheck(null, nextProps)) > -1 && nextProps.price.price && nextProps.price.done) {

      if (nextProps.serviceSettings.isCashBySenderActive) {
        //sender cash


        this.props.dispatch(New_Travel_PaymentMethod({
          paymentSide: "Sender",
          paymentMethod: "Cash",
          paymentProvider: null,
          cardInfo: null,
          paymentMethodId: null,
          log: "from 180"
        }));



      } else if (nextProps.serviceSettings.enableReceiverCash) {
        //sender cash
        this.props.dispatch(New_Travel_PaymentMethod({
          paymentSide: "Receiver",
          paymentMethod: "Cash",
          paymentProvider: null,
          cardInfo: null,
          paymentMethodId: null,
        }));

      }

    }


    if (!nextProps.paymentSide && nextProps.price.price && nextProps.price.done) {

      if (["grey", "red"].indexOf(this.WalletCheck(this.state.paymentSide[0].data, nextProps)) == -1) {
        this.props.dispatch(
          New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Wallet",
          })
        );
      } else if (nextProps.serviceSettings.isCashBySenderActive) {
        this.props.dispatch(
          New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Wallet",
          })
        );
      } else if (nextProps.serviceSettings.enableReceiverCash) {
        this.props.dispatch(
          New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Wallet",
          })
        );
      }

    }

    if (nextProps.customer.id != this.props.customer.id) {
      this.setState({
        cardsLoaded: true
      }, () => {
        if ((get_settings_dispatcher().stripePublicApiKey && get_settings_dispatcher().stripePublicApiKey.length > 0) && nextProps.customer.id && nextProps.customer.id.length > 2) {
          Get_Stripe_Cards(this.setCards, nextProps.customer.id)
        }
      })
    } else {
      if ((get_settings_dispatcher().stripePublicApiKey && get_settings_dispatcher().stripePublicApiKey.length > 0) && nextProps.customer.id && nextProps.customer.id.length > 2 && !this.state.cardsLoaded) {
        this.setState({
          cardsLoaded: true
        })
        Get_Stripe_Cards(this.setCards, nextProps.customer.id)
      }
    }

    // if ((get_settings_dispatcher().stripePublicApiKey && get_settings_dispatcher().stripePublicApiKey.length > 0) 
    //     && nextProps.customer.id && nextProps.customer.id.length > 2 && this.state.cards) {
    //   Get_Stripe_Cards(this.setCards, nextProps.customer.id)
    // }

    if ((!nextProps.customer.id || nextProps.customer.id.length == 0) && this.state.cards.length > 0) {
      this.setState({
        cards: [],
        cardsLoaded: false
      })
    }
  }

  render() {
    const { t } = this.props;


    return (
      <React.Fragment>



        <div className={styles.paymentMethodContainerNewOrder} onClick={() => this.handleshowpoperPaymentSide(true)} ref={this.paymentSide}
          id={this.props.id}
        >


          <div className={styles.pmi} >


            {"SenderCard" == this.props.paymentSide + this.props.paymentMethod ? <PaymentIcon
              icon={this.props.cardInfo.brand}
              style={{ height: '13px' }}
            /> : <IconProvider Icon={this.getImageOfPaymentMeyhod(this.props.paymentSide + this.props.paymentMethod)} size="16" style={{ width: "18px", height: "18px" }} />
            }

          </div>
          <div className={styles.twocolumn}>
            <Typography weight="Body_Middle_Bold" className={styles.firstrow} text={<> {(isEmpty(this.props.customer.id) && "SenderWallet" == this.props.paymentSide + this.props.paymentMethod) ?

              "SenderCard" == this.props.paymentSide + this.props.paymentMethod ? `**** ${this.props.cardInfo.lastNumbers}` :
                <Trans i18nKey="PleaseSelect"></Trans>

              :
              "SenderCard" == this.props.paymentSide + this.props.paymentMethod ? `**** ${this.props.cardInfo.lastNumbers}` :
                <Trans i18nKey={this.props.paymentSide ? (this.props.paymentSide + this.props.paymentMethod) : "PleaseSelect"}></Trans>}
              <IconProvider Icon={ARROW_DOWN_BOLD} className={styles.firstrowimg} size="12" />
            </>} />


            <Typography weight="Body_Tiny_Regular" text={this.props.t("paymentmethod")} />
          </div>







          {/* <div className="opdrtmff">
            {(isEmpty(this.props.customer.id) && "SenderWallet" == this.props.paymentSide + this.props.paymentMethod)  ?
             
             
             (<React.Fragment> <span className="pfkwuhh"> {"SenderCard" == this.props.paymentSide + this.props.paymentMethod ? `**** ${this.props.cardInfo.lastNumbers}` :
                <Trans i18nKey="PleaseSelect"></Trans>} </span>
                <img className={`fowiaaroow ${this.state.showpoperSide && "rotate180"}`} src={arrow} />
              </React.Fragment>)
              
              :

              <React.Fragment>
                {"SenderCard" == this.props.paymentSide + this.props.paymentMethod ? (
                  <PaymentIcon
                    id={this.props.cardInfo.brand}
                    className="dgieh"
                  />
                ) : (
                  <img className="dgieh" src={this.getImageOfPaymentMeyhod(this.props.paymentSide + this.props.paymentMethod)} />
                )}

                <span className="pfkwuhh"> {"SenderCard" == this.props.paymentSide + this.props.paymentMethod ? `**** ${this.props.cardInfo.lastNumbers}` :
                  <Trans i18nKey={this.props.paymentSide ? (this.props.paymentSide + this.props.paymentMethod) : "PleaseSelect"}></Trans>} </span>
                <img className={`fowiaaroow ${this.state.showpoperSide && "rotate180"}`} src={arrow} />
                
                 </React.Fragment>
            }


          </div> */}

          {/* <key>
            <Trans i18nKey={"paymentmethod"}> </Trans>
          </key> */}

        </div>

        <Popper
          open={this.state.showpoperSide}
          anchorEl={this.paymentSide.current}
          role={undefined}
          placement={"bottom"}
          style={{
            zIndex: 6,
            marginTop: "4px",
            marginBottom: "10px",
            border: "solid 1px rgba(128, 128, 128, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
          }}
          transition
          disablePortal
          className={styles.popperMenu}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              timeout={{ enter: 0, exit: 0 }}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={styles.popperMenu_paper}>
                <ClickAwayListener
                  onClickAway={() => this.handleshowpoperPaymentSide(false)}
                >
                  <>

                    <div className={styles.popperMenu_item} onClick={isEmpty(this.props.customer.id) ? null : () => this.WalletCheck(this.state.paymentSide[0].data, this.props) !== "red" && this.handleSelectedItem(this.state.paymentSide[0])}>
                      <div className={styles.popperMenu_inner_item} style={{ cursor: (this.WalletCheck(this.state.paymentSide[0].data, this.props) == "red" || isEmpty(this.props.customer.id)) ? "not-allowed" : "pointer", opacity: (this.WalletCheck(this.state.paymentSide[0].data, this.props) == "red" || isEmpty(this.props.customer.id)) ? ".4" : "1" }}>
                        <IconProvider Icon={wallet2} size="16" />
                        <Typography weight="Body_Middle_Regular" text={this.props.t("Wallet")} />
                      </div>

                      {this.WalletCheck(this.state.paymentSide[0].data, this.props) == "red" ? (
                        <Typography weight="Body_Tiny_Regular" text={this.props.t("balanceError")} />
                      ) : null}
                    </div>

                    {this.props.serviceSettings.isCashBySenderActive && <div className={styles.popperMenu_item} onClick={() =>
                      this.handleSelectedItem(this.state.paymentSide[1])
                    }>
                      <div className={styles.popperMenu_inner_item}>
                        <IconProvider Icon={CASH} size="16" />
                        <Typography weight="Body_Middle_Regular" text={this.props.t("SenderCash")} />
                      </div>
                    </div>}

                    {this.props.serviceSettings.enableReceiverCash && <div className={styles.popperMenu_item} onClick={() =>
                      this.handleSelectedItem(this.state.paymentSide[2])
                    }>
                      <div className={styles.popperMenu_inner_item}>
                        <IconProvider Icon={BY_RECEIVER} size="16" />
                        <Typography weight="Body_Middle_Regular" text={this.props.t("ReceiverCash")} />
                      </div>
                    </div>}




                    {/* <div className="BalanceContainer">
                      <img src={balance} className="balanceicon" />

                      <span className="BalanceText">
                        {" "}
                        <Trans i18nKey={"Balance"}> </Trans>
                      </span>
                      <span className="BalanceNumberText">
                        {" "}
                        {this.props.balance}
                      </span>
                    </div> */}





                    {/* {this.props.serviceSettings.enableReceiverCash && (
                      <div
                        className={`MethodsItem ${this.state.paymentSide[2].i18 ==
                          this.props.paymentSide + this.props.paymentMethod
                          ? "MethodsItemActive"
                          : ""
                          }`}
                        onClick={() =>
                          this.handleSelectedItem(this.state.paymentSide[2])
                        }
                      >
                        <img src={ReceiverCashic} />
                        <span>
                          {" "}
                          <Trans i18nKey={"ReceiverCash"}> </Trans>{" "}
                        </span>
                      </div>
                    )} */}

                    {this.state.cards.map((card) => {
                      return <div className={styles.popperMenu_item} onClick={() =>
                        this.handleSelectedItemStripe(card)
                      }>
                        <div className={styles.popperMenu_inner_item}>
                          <PaymentIcon
                            icon={card.brand}
                            style={{ width: '16px' }}
                          />
                          <Typography weight="Body_Middle_Regular" text={`**** ${card.lastNumbers}`} />
                        </div>
                      </div>


                      // <div
                      //   className={`MethodsItem ${("SenderCard" ==
                      //     this.props.paymentSide + this.props.paymentMethod
                      //   ) && this.props.paymentMethodId == card.id ? "MethodsItemActive"
                      //     : ""
                      //     }`}
                      //   onClick={() =>
                      //     this.handleSelectedItemStripe(card)
                      //   }

                      // >
                      //   <PaymentIcon
                      //     id={card.brand}
                      //     style={{ height: '16px' }}
                      //   />
                      //   <span>
                      //     {`**** ${card.lastNumbers}`}
                      //   </span>
                      // </div>
                    })}

                    {(get_settings_dispatcher().stripePublicApiKey && get_settings_dispatcher().stripePublicApiKey.length > 0 && this.props.customer.id) && <div className={styles.popperMenu_item_hr}></div>}

                    {(get_settings_dispatcher().stripePublicApiKey && get_settings_dispatcher().stripePublicApiKey.length > 0 && this.props.customer.id) && <Stripe_Add_Card onClose={(autoSelect) => this.getCards(autoSelect)} />}
                  </>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>



      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  price: state.NewOrder.newOrder.price,
  balance: state.NewOrder.newOrder.customer.balance,
  active_service: state.NewOrder.newOrder.service.activeVehicleType,
  paymentMethodId: state.NewOrder.newOrder.paymentMethod.paymentMethodId,
  cardInfo: state.NewOrder.newOrder.paymentMethod.cardInfo,
  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
  serviceSettings: state.NewOrder.newOrder.service.activeVehicleType
    ? state.NewOrder.newOrder.service.activeVehicleType.setting
    : { enableReceiverCash: false, isCashBySenderActive: false },
  direction: state.LocalData.direction,
  customer: state.NewOrder.newOrder.customer,

  type: state.NewOrder.newOrder.customer.type,
  canWalletBeNegative: state.NewOrder.newOrder.customer.canWalletBeNegative || false,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
