import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InitialViewOrder } from './initial-viewOrder';
import styles from './ViewOrder.module.css';
import { DetailsContainer } from '../../components/view-order/details-container'
import { Header } from '../../components/view-order/header';
import { Status } from '../../components/view-order/status';
import { CustomerService } from '../../components/view-order/customer-service';
import { DriverVehicle } from '../../components/view-order/driver-vehicle';
import { PickupDropoffs } from '../../components/view-order/pickup-dropoffs';
import { Footer } from '../../components/view-order/footer';
import { useLocation, useParams } from "react-router-dom";
import { PickupLink } from '../../components/view-order/pickup-link';
import { DropoffLink } from '../../components/view-order/dropoff-link';
import EventTimeLine from '../../components/view-order/event_time-line';
import { MapContainer } from '../../components/shared/map-container';
import { useViewOrderMapContainerWidthHeight } from '../../hooks/useViewOrderMapContainerWidthHeight';
import { MapViewOrderPinOnMap } from '../../components/shared/Map-ViewOrder-pin-onMap';
import { MapEditOrderPinOnMap } from '../../components/shared/Map-EditOrder-pin-onMap';
import { MapTrackDriverPinOnMap } from '../../components/shared/Map-TrackDriver-pin-onMap';
import { MapOrderRouteOnMap } from '../../components/shared/Map-Order-Route-OnMap';
import { MapCircleRipple } from '../../components/shared/Map-CircleRipple';
import { Monitoring } from '../../components/main/moitoring';
import AdvancedAssaigning from "../../Entries/Main/components/AdvancedAssaigning/Driver";
import EditOrder from "../../Entries/Main/components/EditPickup&DropOff";
import PriceDetails from '../../components/view-order/price-details';
import NewOrder from "../../Entries/Main/components/NewOrder";
import { MapNewOrderPinOnMap } from '../../components/shared/Map-NewOrder-pin-onMap';
import { MapNewEventPinOnMap } from '../../components/shared/Map-New-Events-Pins-On-Map';
import MapNewOrderPin from '../../components/shared/Map-NewOrder-Select-pin';
import MapEditOrderPin from '../../components/shared/Map-EditOrder-Select-pin';
import EstimatedRoutePanel from '../../components/shared/Map-Estimated-Route-Panel';
import New_Fav_SelectedGenerator from '../../components/shared/Map-New-Fav-SelectedGenerator';
import New_Event_SelectedGenerator from '../../components/shared/Map-New-Event-SelectedGenerator';
import { DriverList } from "../../components/main/driver-list";
import { useCSSTOTEXT } from '../../hooks/useCSSTOTEXT';
import { ViewOrderMonitoring } from '../../components/view-order/monitoring';
import { ViewOrderEstimate } from '../../components/view-order/estimate-path-time';
import PickupDropoffsDetails from '../../components/view-order/pickup-dropoffs-details';
import Offering from '../../components/view-order/offering';
import { OrderInfoAction } from '../../Redux/actions/actions';
import Voip from "../../Entries/Voip";
import { ChangeViewTravelDialoge, SetDriverListOpenDialoge, SetSharedDataVehicleTypes } from '../../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';
import { ViewOrderRoute } from '../../components/view-order/route';
import { ViewOrderNote } from '../../components/view-order/notes';
import { RouteListMonitoring } from '../../components/route-details/monitoring';
import { DRIVER_COLOR } from '../../assets';
import Typography from '../../design-system/lib/Typography';
import { ServicesApi } from '../../service/API/service';

export function ViewOrderLayout(props) {
    const MapContainerWidthHeight = useViewOrderMapContainerWidthHeight();
    let { orderId } = useParams();
    const pureData = useSelector((state) => state.ViewTravel.pureData)
    const Globalsettings = useSelector((state) => state.SharedData.settings)

    const orderNotFound = useSelector((state) => state.ViewTravel.orderNotFound)
    const orderError = useSelector((state) => state.ViewTravel.get_order_error)

    const EditOrderOpenDialoge = useSelector((state) => state.EditOrder.OpenDialoge)
    let CSSTOTEXT = useCSSTOTEXT()
    const [loaded, setLoaded] = useState(false);
    const AdvancedAssaigningOpenDialoge = useSelector((state) => state.AdvancedAssaigning.OpenDialoge)
    const dispatch = useDispatch();
    const mainLoaded = useSelector((state) => state.SharedData.mainLoaded)
    const { t } = useTranslation();

    const location = useLocation();

    useEffect(() => {
        dispatch(
            ChangeViewTravelDialoge(true, orderId, null)
        );
        dispatch(
            OrderInfoAction(dispatch, {
                orderId: orderId,
            })
        );
    }, [orderId]);


    useEffect(() => {
        !mainLoaded && InitialViewOrder(orderId, (call) => {
            setLoaded(call);
        })
    }, [mainLoaded])

    useEffect(() => {
        getVehicleType()
    }, [])

    const getVehicleType = React.useCallback( async () => {
        const Services = new ServicesApi();
 
        const VehicleTypedata = await Services.VehicleType()
        dispatch(SetSharedDataVehicleTypes(VehicleTypedata.VehicleTypes))

      }, []);
    

    if (pureData) {
        return <>
            <DetailsContainer>
                <div className={styles.innerContainer}>
                    <Header />
                    <div className={styles.innerContainerComponents}>
                        <Status />
                        <CustomerService />
                        <DriverVehicle />
                        <ViewOrderRoute />
                        <PickupDropoffs />
                        {/* <PickupLink />
                        <DropoffLink /> */}
                        <ViewOrderNote />
                    </div>
                </div>
                <Footer />
            </DetailsContainer>

            <Offering />
            <PickupDropoffsDetails />
            <PriceDetails />
            <EventTimeLine />


            <EditOrder />
            <NewOrder />

            <MapNewOrderPin />
            <MapEditOrderPin />
            {/* <EstimatedRoutePanel /> */}
            <New_Fav_SelectedGenerator />
            <New_Event_SelectedGenerator />

            <div className={styles.monitoringEsContainer} style={EditOrderOpenDialoge ? { [`${CSSTOTEXT["--main-defaultFloat"]}`]: "585px" } : { [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px" }}>
                <ViewOrderEstimate />
               {pureData.type == "Ondemand" &&  <RouteListMonitoring />}
            </div>

            {AdvancedAssaigningOpenDialoge &&
                <AdvancedAssaigning />
            }
            {/* <Voip /> */}

          {pureData.type == "Ondemand" &&   <div className={(Globalsettings && Globalsettings.dispatcherHomeMessage && String(Globalsettings.dispatcherHomeMessage).length > 0) ? styles.driversBtnToggle2 :styles.driversBtnToggle} onClick={()=>{  dispatch(SetDriverListOpenDialoge(true))}}>
            <img src={DRIVER_COLOR} />
            <Typography text={t("Drivers")} weight='Body_Middle_Bold' style={{color:"rgba(72, 72, 72, 1)"}}/>
            </div>}

        </>
    } else {
        if (orderNotFound) {
            return <DetailsContainer>

                <span className={styles.orderNotFound}> {orderNotFound} </span>
                <div class="illustration">
                    <div class="circle"></div>
                    <div class="clip">
                        <div class="paper">
                            <div class="face">
                                <div class="eyes">
                                    <div class="eye eye-left"></div>
                                    <div class="eye eye-right"></div>
                                </div>
                                <div class="rosyCheeks rosyCheeks-left"></div>
                                <div class="rosyCheeks rosyCheeks-right"></div>
                                <div class="mouth"></div>
                            </div>
                        </div>
                    </div>
                </div>


            </DetailsContainer>
        } else if (orderError) {
            return <span class="loader2"></span>
        } else {
            return <span class="loader2"></span>
        }


    }

}


const Loading = () => {
    return <div style={{ float: "left", marginLeft: "calc(50vw - 40px)", marginTop: 'calc(50vh - 40px)' }}>
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
}